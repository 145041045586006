<template>
	<div>
		<ts-page-title :title="$t('reportEmployeeProfile.pageTitle')" :breadcrumb="[
			{ text: $t('home'), href: '/' },
			{
				text: $t('reportEmployeeProfile.pageTitle'),
				active: true
			}
		]" />
		<ts-panel>
			<ts-panel-wrapper>
				<div class="row">
					<div class="col-lg-3">
						<div class="card border-0 bg-gray-900">
							<div class="card-body">
								<div class="text-gray-500">
									<b>{{
										$t('disbursementList.filterCriteria')
									}}</b>
								</div>
							</div>
							<div class="widget-list rounded-bottom">
								<div class="mb-3 mt-3">
									<div class="col-md-12">
										<label class="form-col-label control-label required">{{
											$t("reportEmployeeProfile.branch")
										}}</label>
										<ts-branch-filter @filter="value => (model.branch_id = value)"
											:isPlaceholder="true"
											:class="{ 'is-invalid': errors.has('template_id') }" />
										<div class="invalid-feedback" v-if="errors.has('branch_id')">
											{{ errors.first('branch_id') }}
										</div>
									</div>
								</div>
								<div class="mb-3">
									<RadioGroup v-model="id_type">
										<Radio class="mt-1" :label="c.value" border v-for="(c,
										index) in calculateTypes" :key="index">
											{{ c.label }}
										</Radio>
									</RadioGroup>
								</div>
								<div class="mb-3" v-if="id_type == 1">
									<div class="col-md-12">
										<label>{{
											$t('reportEmployeeProfile.orgChartStructure')
										}}</label>
										<Select v-model="model.org_chart_structure_id" filterable :loading="loading"
											class="from-label" :placeholder="$t('all')" :class="{
												'is-invalid': errors.has(
													'org_chart_structure_id'
												)
											}" :clearable="true" @on-change="onOrgChange">
											<Option v-for="(org,
											index) in orgStructure" :value="org.org_chart_structure_id" :key="index">
												{{
													org.org_chart_structure
												}}
											</Option>
										</Select>
									</div>
								</div>
								<div class="mb-3" v-if="id_type == 2">
									<div class="col-md-12">
										<label>{{
											$t('reportEmployeeProfile.shiftName')
										}}</label>
										<Select v-model="model.shift_id" filterable :loading="loading"
											class="from-label" multiple :max-tag-count="2" :placeholder="$t('all')"
											@on-change="onShiftChange">
											<Option v-for="(s, index) in shift" :value="s.shift_id" :key="index">
												{{
													s.shift_name +
														'-' +
														s.shift_type
												}}
											</Option>
										</Select>
									</div>
								</div>
								<div class="col-md-12 tw-mb-3">
									<label class="form-col-label control-label">{{
										$t('reportEmployeeProfile.status')
									}}</label>
									<Select v-model="model.status_id" multiple :max-tag-count="1" :filterable="true"
										:filter-by-label="true" :placeholder="$t('all')">
										<Option v-for="item in status" :value="item.status_id" :key="item.status_id"
											:label="item.status">
											{{ item.status }}
										</Option>
									</Select>
								</div>
								<div class="tw-justify-end tw-flex tw-space-x-2">
									<ts-button outline color="success" @click.prevent="exportExcel"
										:waiting="exporting">
										<i class="far fa-file-excel" v-if="!exporting"></i>
										{{ $t('exportExcel') }}</ts-button>
									<ts-button color="danger" outline @click.prevent="preview" :waiting="waiting">
										<i class="far fa-file-pdf" v-if="!waiting"></i>
										{{ $t('previewPdf') }}</ts-button>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-9">
						<div id="container">
							<ts-preview-pdf v-model="waiting" :src="src" />
						</div>
					</div>
				</div>
			</ts-panel-wrapper>
		</ts-panel>
	</div>

</template>

<script>
import Errors from 'form-backend-validation/dist/Errors';
import { mapState, mapActions } from 'vuex';
import * as FileDownload from 'downloadjs'
import { trim, debounce } from 'lodash'
export default {
	name: "employee-profile",
	data() {
		return {
			errors: new Errors(),
			waiting: false,
			loading: false,
			exporting: false,
			viewing: false,
			orgStructure: [],
			shift: [],
			src: '',
			status: [],
			id_type: 1,
			calculateTypes: [
				{
					value: 1,
					label: 'Organizational Structure'
				},
				{
					value: 2,
					label: 'Shift'
				}
			],
			model: {
				org_chart_structure_id: null,
				shift_id: null,
				status_id: null,
				branch_id: null
			}
		}
	},
	computed: {
		...mapState('report/employeeProfile', [
			'resources',
			'pagination'
		]),
	},
	methods: {
		...mapActions('report/employeeProfile', ['getFormViewData']),
		preview() {
			this.errors = new Errors()
			this.waiting = true
			this.src = ''
			this.$store
				.dispatch('report/employeeProfile/employeeProfile', this.model)
				.then(response => {
					this.src = response.url
				})
				.catch(error => {
					this.notice({ type: 'error', text: error.message })
					this.errors = new Errors(error.errors)
				})
				.finally(() => {
					this.waiting = false
				})
		},
		async fetchResource(attributes) {
			this.loading = true
			let response = await this.getFormViewData({
				params: attributes
			})
			if (attributes.fnName) {
				attributes.fnName.split(',').forEach(fn => {
					this[fn] = response[fn]
				})
			}
			this.loading = false
		},
		exportExcel() {
			this.errors = new Errors()
			this.exporting = true

			this.$store
				.dispatch('report/employeeProfile/exportExcel', {
					branch_id: this.model.branch_id,
					employee_id: this.employee_id,
					id_type: this.id_type,
					shift_id: this.model.shift_id,
					org_chart_structure_id: this.model.org_chart_structure_id,
					status_id: this.model.status_id,
				})
				.then(response => {
					let filename = trim(
						response.headers['content-disposition']
							.substring(
								response.headers['content-disposition'].indexOf(
									'filename'
								)
							)
							.replace('filename=', ''),
						'"'
					)
					FileDownload(response.data, filename)
				})
				.catch(err => {
					let error = JSON.parse(
						String.fromCharCode.apply(null, new Uint8Array(err))
					)
					this.notice({ type: 'error', text: error.message })
					this.errors = new Errors(error.errors)
				})
				.finally(() => (this.exporting = false))
		},
		async onOrgChange(value) {
			this.employee_id = []
			this.fetchResource({
				fnName: 'employeeProfile',
				org_chart_structure_id: value
			})
		},
		async onShiftChange(value) {
			this.employee_id = []
			this.fetchResource({
				fnName: 'employeeProfile',
				shift_id: value
			})
		},
		notice(not) {
			this.$Notice[not.type]({
				title: 'EMPLOYEE PROFILE',
				desc: not.text
			})
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.fetchResource({
				fnName: 'orgStructure,shift,status'
			})
		})
	},
	watch: {
		id_type: function (value) {
			if (value == 1) {
				this.shift_id = null
			} else {
				this.org_chart_structure_id = null
			}
		},
		branch_id: debounce(function (value) {
			this.employee_id = []
			this.fetchResource({
				fnName: 'employeeProfile',
				branch_id: value
			})
		}, 500)
	},
}

</script>

<style>

</style>